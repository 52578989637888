import * as Sentry from "@sentry/remix";
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
import React from "react";
import * as ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { ClientStyleCacheProvider } from "./context/clientStyleContext";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";

Sentry.init({
    dsn: "https://5b9d2e72b7a02ecf92a7d4e4246f0163@o566422.ingest.us.sentry.io/4507221173796864",
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    enabled: false, //read from environment while supporting typescript checking (maybe using Zod!)
    integrations: [Sentry.browserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useMatches
    // eslint-disable-next-line import/namespace
    }), Sentry.replayIntegration()]
})

const hydrate = () => {
  React.startTransition(() => {
    ReactDOM.hydrateRoot(
      document,
      <ClientStyleCacheProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
          <RemixBrowser />
      </ClientStyleCacheProvider>,
    );
  });
};

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}